import { useState } from "react"

const ImgInput = (props) => {
    const [imageFile, setImgFile] = useState("")

    const handleClick = async() => {
        try {
            const data = new FormData()
            data.append("file", imageFile)
            data.append("upload_preset", "wua3rwn1")
            data.append("cloud_name", "dahik9vaf")
            const response = await fetch("https://api.cloudinary.com/v1_1/dahik9vaf/image/upload", {method: "POST", body: data})
            const jsonData = await response.json()
            await props.setImage(jsonData.url)
            alert("画像アップロード成功")
        } catch (error) {
            alert("画像アップロード失敗")
        }
    } 

    return (
        <div className="img-input">
            <input type="file" onChange={(e) => setImgFile(e.target.files[0])} accept="image/png, image/jpg" />
            <button onClick={handleClick} disabled={!imageFile}>画像アップロード</button>
        </div>
    )

}
export default ImgInput