import {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import useAuth from "../../utils/useAuth"

const ReadAll = () => {
    const [allItems, setAllItems] = useState()
    const auth = useAuth()
    

    useEffect(() => {
        if(auth) {
            const getAllItems = async() => {
                const response = await fetch("https://mern-stack-backend.bcw-mt.com:5000/")
                const jsonResponse = await response.json()
                setAllItems(jsonResponse)
            }
            getAllItems()
        } 
    },[auth])
    return (
        <div>
            <div className="grid-container-in">
                {allItems && allItems.allItems.map(item => 
                <Link to={`/item/${item._id}`} key={item._id} className="card">
                    
                    <img src={item.image} alt="item"/>
                    <div className="texts-area">
                        <h2>¥{item.price}</h2>
                        <h3>{item.title}</h3>
                        <p>{item.description.substring(0,80)}...</p>
                    </div>
                </Link>
                )}
            </div>
        </div>

    )
}

export default ReadAll